import React from "react";

const PodcastsAndVideosHeader = ({goBack, openShareModal, headerTitle}) => {
    return (
        <div className="flex cursor-pointer">
            <div
                className="w-full flex flex-row justify-center items-center h-full gap-2 bg-linear-header-media p-4 pt-6">
                <div className="w-full">
                    <div className="flex flex-row gap-0.5">
                        <div
                            onClick={goBack}
                            className="w-[25px] h-[25px] flex flex-row items-center justify-center font-anton text-[#1b062f] text-[10px] bg-[#d2ff00] cursor-pointer gap-1"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14px"
                                height="14px"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="m14 18l-6-6l6-6l1.4 1.4l-4.6 4.6l4.6 4.6z"
                                />
                            </svg>
                        </div>
                        <div
                            className="flex justify-center items-center w-[64px] h-[25px] text-[10px] bg-[#d2ff00] cursor-pointer text-main"
                            onClick={goBack}>
                            <span>All Media</span>
                        </div>
                        <div className="w-full flex justify-end">
                            <button
                                className="w-[30px] h-[30px] flex justify-center items-center"
                                style={{border: "1px solid #D2FF00"}}
                                onClick={openShareModal}
                            >
                                <img src="../img/public/Union.png"/>
                            </button>
                        </div>
                    </div>
                    <h1 className="text-[30px] md:text-[55px]">{headerTitle}</h1>
                </div>
            </div>
        </div>
    );
};

export default PodcastsAndVideosHeader;
